<template>
    <footer class="bg-black z-10" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <img class="h-7 hover:scale-[125%] duration-[400ms] hover:-rotate-180" src="/assets/icon.svg" alt="Synci.io" />
                <div class="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
                    <div class="md:grid md:grid-cols-3 md:gap-8">
                        <div>
                            <h3 class="text-sm font-semibold leading-6 text-zinc-50">Features</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.features" :key="item.name">
                                    <Link :href="item.href" class="text-sm leading-6 text-zinc-400 hover:text-zinc-50">{{ item.name }}</Link>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-sm font-semibold leading-6 text-zinc-50">Support</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.support" :key="item.name">
                                    <a v-if="item.external" target="_blank" :href="item.href" class="text-sm leading-6 text-zinc-400 hover:text-zinc-50">{{ item.name }}</a>
                                    <Link v-else :href="item.href" class="text-sm leading-6 text-zinc-400 hover:text-zinc-50">{{ item.name }}</Link>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-sm font-semibold leading-6 text-zinc-50">Policies</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.policies" :key="item.name">
                                    <Link :href="item.href" class="text-sm leading-6 text-zinc-400 hover:text-zinc-50">{{ item.name }}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="false" class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
                <div>
                    <h3 class="text-sm font-semibold leading-6 text-white">Subscribe to our newsletter</h3>
                    <p class="mt-2 text-sm leading-6 text-zinc-300">The latest news, articles, and resources, sent to your inbox weekly.</p>
                </div>
                <form class="mt-6 sm:flex sm:max-w-md lg:mt-0">
                    <label for="email-address" class="sr-only">Email address</label>
                    <input type="email" name="email-address" id="email-address" autocomplete="email" required="" class="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6" placeholder="Enter your email" />
                    <div class="mt-4 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                        <button type="submit" class="flex w-full items-center justify-center rounded-md bg-indigo-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
                    </div>
                </form>
            </div>
            <div class="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                <div class="flex space-x-6 md:order-2">
                    <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" target="_blank" class="text-zinc-500 hover:text-zinc-400">
                        <span class="sr-only">{{ item.name }}</span>
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                    </a>
                </div>
                <p class="mt-8 text-xs leading-5 text-zinc-400 md:order-1 md:mt-0">&copy; 2024 Synci. All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {defineComponent, h, ref} from 'vue'
import {Link} from '@inertiajs/vue3'

const navigation = [
    { name: 'Home', href: '#home' },
    { name: 'Features', href: '#features' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'Company', href: '#footer' },
]

const mobileMenuOpen = ref(false);

const footerNavigation = {
    features: [
        { name: 'Free File Importer', href: route('import') },
        { name: 'Supported Banks', href: route('home.features.supported-banks') },
    ],
    support: [
        { name: 'Pricing', href: route('home') + '#pricing' },
        { name: 'Contact Us', href: route('home.contact') },
        { name: 'Changelog', href: 'https://synci.featurebase.app/changelog', external: true },
        { name: 'Roadmap', href: 'https://synci.featurebase.app/roadmap', external: true },
    ],
    policies: [
        { name: 'Terms of Service', href: route('home.terms') },
        { name: 'Acceptable Use', href: route('home.acceptable-use') },
        { name: 'Privacy Policy', href: route('home.privacy') },
        { name: 'Cookie Policy', href: route('home.cookies') },
    ],
    social: [
        {
            name: 'Reddit',
            href: 'https://www.reddit.com/r/synci',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 512 512' }, [
                        h('path', {
                            d: 'M373 138.6c-25.2 0-46.3-17.5-51.9-41l0 0c-30.6 4.3-54.2 30.7-54.2 62.4l0 .2c47.4 1.8 90.6 15.1 124.9 36.3c12.6-9.7 28.4-15.5 45.5-15.5c41.3 0 74.7 33.4 74.7 74.7c0 29.8-17.4 55.5-42.7 67.5c-2.4 86.8-97 156.6-213.2 156.6S45.5 410.1 43 323.4C17.6 311.5 0 285.7 0 255.7c0-41.3 33.4-74.7 74.7-74.7c17.2 0 33 5.8 45.7 15.6c34-21.1 76.8-34.4 123.7-36.4l0-.3c0-44.3 33.7-80.9 76.8-85.5C325.8 50.2 347.2 32 373 32c29.4 0 53.3 23.9 53.3 53.3s-23.9 53.3-53.3 53.3zM157.5 255.3c-20.9 0-38.9 20.8-40.2 47.9s17.1 38.1 38 38.1s36.6-9.8 37.8-36.9s-14.7-49.1-35.7-49.1zM395 303.1c-1.2-27.1-19.2-47.9-40.2-47.9s-36.9 22-35.7 49.1c1.2 27.1 16.9 36.9 37.8 36.9s39.3-11 38-38.1zm-60.1 70.8c1.5-3.6-1-7.7-4.9-8.1c-23-2.3-47.9-3.6-73.8-3.6s-50.8 1.3-73.8 3.6c-3.9 .4-6.4 4.5-4.9 8.1c12.9 30.8 43.3 52.4 78.7 52.4s65.8-21.6 78.7-52.4z',
                        }),
                    ]),
            }),
        }
    ],
}
</script>

<script>
export default {
    name: "Navbar"
}
</script>

<style scoped>

</style>
